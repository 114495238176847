<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.title"
                :disabled="isReadOnly('title')"
                label="Заголовок"
                name="choiceTitle"
                persistent-hint
                hint="заголовок ответа"
                @input="$emit('update:title', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VCheckbox
                :value="entity.right"
                :disabled="isReadOnly('right')"
                :true-value="true"
                :false-value="false"
                label="Верный"
                name="choiceRight"
                persistent-hint
                hint="верный ответ"
                @change="$emit('update:right', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.questionId"
                :items="questions"
                :filter="customFilter"
                :disabled="isReadOnly('questionId')"
                item-text="name"
                item-value="id"
                label="Вопрос"
                name="choiceQuestionId"
                persistent-hint
                hint="ответ вопроса"
                @input="$emit('update:questionId', $event)"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.title" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.title" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'ChoiceForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    questions: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание ответа';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование ответа';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    customFilter(item, queryText) {
      const title = item.title.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return title.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },

    isReadOnly(key) {
      return this.readOnly.includes(key);
    },
  },
};
</script>
